.sidebar-position {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}
.sidebar-container {
  background-color: var(--secondary-color);
  height: 100vh;
  padding: 10px 0;
  /* overflow: hidden; */
  width: 60px;
  transform: 0.5s;
}
.sidebar-icon {
  font-size: 25px;
  text-align: center;
  padding: 10px 0;
  color: var(--info-color);
  cursor: pointer;
  outline: none;
}

/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width: 1281px) {
}

/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px) {
}

/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) {
  .header-container {
    padding: 0 15px;
  }
  .btn-group button:first-child,
  .btn-group button:last-child {
    font-size: 16px;
    padding: 8px 40px;
  }
  .menu-icon {
    display: none;
  }
  .sidebar-position {
    display: none;
  }
}

/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .header-container {
    padding: 0 15px;
  }
  .btn-group button:first-child,
  .btn-group button:last-child {
    font-size: 16px;
    padding: 8px 40px;
  }
  .menu-icon {
    display: none;
  }
  .sidebar-position {
    display: none;
  }
}

/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
  .header-container {
    padding: 0 15px;
  }
  .btn-group button:first-child,
  .btn-group button:last-child {
    font-size: 16px;
    padding: 8px 40px;
  }
  .menu-icon {
    display: none;
  }
  .sidebar-position {
    display: none;
  }
}

/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
  .header-container {
    padding: 0 15px;
  }
  .btn-group button:first-child,
  .btn-group button:last-child {
    font-size: 16px;
    padding: 8px 20px;
  }
  .menu-icon {
    display: none;
  }
  .sidebar-position {
    display: none;
  }
}

.lang-text {
  font-size: large;
  background-color: #fff;
  padding: 5px 10px;
  display: inline-block;
  width: 100%;
  border-radius: 5px;
}
.lang-text:hover {
  background-color: #d6f2f4;
}
.lang-active {
  color: #d6f2f4 !important;
  background-color: rgb(107, 103, 235) !important;
}
