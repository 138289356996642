.payment-image {
    width: 70px;
    margin: 0 5px;
    cursor: pointer;
}
.payment-image img {
    width: 100%;
    border-radius: 10px;
}
.payment-list {
    position: relative;
    padding: 5px;
    width: 50%;
}

.payment-check {
    display: none;
}
.check-icon {
    color: var(--success-color);
    font-size: 16px;
    position: absolute;
    right: 10px;
    bottom: 5px;
}


/* --------- */
.payment_acc_inner{
    background:rgb(250, 250, 250,0.1);
    border-radius: 5px;
    box-shadow: 1px 1px 5px #000;
    padding:10px;
    font-weight: 300;
    font-size:14px;
    /* margin-top:7px; */
    text-align: center;
    cursor: pointer;
}

.payment_img img{
    width: 40px !important;
    height: 40px !important;
    object-fit: contain;
    border-radius: 5px;
}
.active {
    background: #6b67eb;
    border-radius: 5px;
}
.phone_payment {
    margin-top: 5px;
    display: inline-block;
}

@media (min-width: 320px) and (max-width: 500px) {
    .payment-list {
        width: 100%;
    }
}