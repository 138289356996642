.container-main {
    padding: 0 100px 70px 100px;
    position: relative;
}
.auth-card {
    width: 500px;
    background-color: var(--secondary-color);
    border-radius: 10px;
    margin: 30px auto 0 auto;
}
.auth-card-header {
    padding: 10px;
    background-color: #0f0d29;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
}
.auth-card-header span {
    text-transform: uppercase;
    display: block;
    letter-spacing: 1px;
    cursor: pointer;
    user-select: none;
    border-bottom: 2px solid transparent;
    padding: 10px;
    font-size: 14px;
}
.auth-card-header span.active {
    border-bottom-color: var(--info-color);
}

/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width : 1281px){

}


/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px){

}


/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px){

}


/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation : landscape){
   .container-main {
      padding: 0 15px 70px 15px;
   }
}


/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
    .container-main {
        padding: 0 15px 70px 15px;
     }

     .auth-card {
        width: 100%;
     }
}


/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
   .container-main {
      padding: 0 15px 70px 15px;
   }
   .auth-card {
      width: 100%;
   }
}