.container-main {
    padding: 70px 100px;
    position: relative;
}
.auth-card {
    width: 500px;
    background-color: var(--secondary-color);
    border-radius: 20px;
    margin: 0 auto;
}
.auth-card-header {
    padding: 20px;
    background-color: #0f0d29;
    border-radius: 20px 20px 0 0;
    text-align: center;
    font-size: 20px;
}
.auth-card-body {
    padding: 20px;
    border-radius: 0 0 20px 20px;
}
.auth-card-body div {
    justify-content: center;
}
.logo-position {
    text-align: center;
    margin-bottom: 20px;
}
.logo {
    width: 130px;
    cursor: pointer;
}

.forgotpass-submit {
    text-align: center;
    margin-top: 40px;
}
/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width : 1281px){

}


/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px){

}


/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px){

}


/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation : landscape){
   .container-main {
      padding: 70px 15px;
   }
}


/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
    .container-main {
        padding: 70px 15px;
     }

     .auth-card {
        width: 100%;
     }
}


/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
   .container-main {
      padding: 70px 15px;
   }
   .auth-card {
      width: 100%;
   }
}