 .input-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
 }
  .toggle-icon {
     font-size: 25px;
     border: 2px solid var(--info-color);
     border-left: none;
     width: 30px;
     height: 50px;
     text-align: center;
     border-radius: 0 25px 25px 0;
     line-height: 50px;
     position: relative;
    }
  .icon {
    position: absolute;
    cursor: pointer;
    right: 15px;
  }
  .input-enclose input {
    height: 50px;
    border: none;
    width: 0;
    border: 2px solid var(--info-color);
    border-right: none;
    border-radius: 25px 0 0 25px;
    background-color: transparent;
    transition: all .3s;
    color: #f5f5f5;
    padding: 10px 10px 10px 20px;
    font-size: 17px;
  }
  
  .input-enclose input:focus {
    width: 300px;
    height: 50px;
    outline: none;
  }


/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width : 1281px){

}


/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px){

}


/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px){

}


/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation : landscape){
  
}


/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
  .input-enclose input:focus {
    width: 250px;
  }
}


/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
  .input-enclose input:focus {
    width: 270px;
  }
}