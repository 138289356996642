@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --main-color: #010320;
  --secondary-color: #181637;
  --success-color: #5abc1c;
  --success-hover-color: #344e22;
  --info-color: #6b67eb;
  --info-hover-color: #333258;
  --danger-color: #e01b1b;
}

body {
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  overflow: hidden auto;
  color: #f5f5f5;
  background-color: var(--main-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 16px;
}
ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

*::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.badge {
  font-size: small;
  padding: 2px 5px;
  border-radius: 5px;
}
.bg-info {
  background-color: var(--info-color);
}
.bg-success {
  background-color: var(--success-color);
}
.bg-danger {
  background-color: var(--danger-color);
}
