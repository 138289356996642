.sidebar-position {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  transform: 0.5s;
}
.sidebar-container {
  width: 288px;
  background-color: var(--secondary-color);
  height: 100vh;
  padding: 10px 0;
  transition: 0.5s;
}
.sidebar-icon {
  font-size: 25px;
  text-align: start;
  color: var(--info-color);
  cursor: pointer;
  padding: 0 20px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  display: flex;
  align-items: center;
}
.sidebar-text {
  font-size: 18px;
  color: #f5f5f5;
  cursor: pointer;
}
.icon-margin {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.hover-underline {
  position: relative;
  line-height: 22px;
  padding: 12px 0;
}
.menu-dis {
  padding: 12px 0;
}

.hover-underline::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--info-color);
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.sidebar-icon:hover .hover-underline::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.sidebar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000a3;
  z-index: 99;
  display: none;
  transition: 0.5s;
}

/* .sidebar-icon:last-child {
  margin-top: 20px;
  display: none;
} */
.responsiveToggle {
  margin-top: 20px;
  display: none;
}
/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width: 1281px) {
}

/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px) {
}

/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .header-container {
    padding: 0 15px;
  }
  .btn-group button:first-child,
  .btn-group button:last-child {
    font-size: 16px;
    padding: 8px 40px;
  }
  .sidebar-position {
    left: -200%;
  }
  .responsiveToggle {
    display: block;
  }
}

/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
  .header-container {
    padding: 0 15px;
  }
  .btn-group button:first-child,
  .btn-group button:last-child {
    font-size: 16px;
    padding: 8px 40px;
  }
  .sidebar-position {
    left: -200%;
  }
}

/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
  .header-container {
    padding: 0 15px;
  }
  .btn-group button:first-child,
  .btn-group button:last-child {
    font-size: 16px;
    padding: 8px 20px;
  }
  .sidebar-position {
    left: -200%;
  }
}

.lang-text {
  font-size: large;
  background-color: #fff;
  padding: 5px 10px;
  display: inline-block;
  width: 100%;
  border-radius: 5px;
}
.lang-text:hover {
  background-color: #d6f2f4;
}
.lang-active {
  color: #d6f2f4 !important;
  background-color: rgb(107, 103, 235) !important;
}
