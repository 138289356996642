.auth-card-body {
    padding: 20px;
}

.submit-position {
    text-align: center;
}

/* --------------------------- */
.payment-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.payment-select {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.payment-select img {
    width: 50px;
    margin-right: 15px;
    border-radius: 10px;
}
.payment-select p {
    margin-bottom: 0;
    margin-right: 15px;
    font-weight: bolder;
}
.payment-select p:first-child {
    font-size: 20px;
    margin-bottom: 5px;
}
.payment-select p:last-child {
    font-size: 14px;
}
.payment-select span {
    font-size: 25px;
    cursor: pointer;
}

.amount-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

/* ---------------------------- */
.confirm-container p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    margin-bottom: 20px;
}
.btn-position { 
   text-align: center;
}
.note {
    font-size: 14px;
    color: red;
    margin-bottom: 10px;
}