.banner-img {
    width: 100%;
}
.swiper-pagination {
    text-align: right;
}
.swiper-pagination-bullet {
    background-color: var(--info-color);
    transition: 0.5s;
}
.swiper-pagination-bullet-active {
    width: 30px;
    height: 8px;
    border-radius: 5px;
}