.container-main {
   padding: 0 100px 70px 100px;
    position: relative;
}

.provider-cards-list {
   margin-top: 20px;
   margin-bottom: 20px;
}
.games-header {
   margin-bottom: 20px;
   position: sticky;
   top: 66px;
   z-index: 9;
   padding: 10px 0;
   background-color: var(--main-color);
}
.game-header-flex {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 30px;
}
.pagi-position {
   display: flex;
   justify-content: center;
}
.totalGame {
   display: flex;
   align-items: center;
}
.totalGame span {
   display: block;
   font-size: 18px;
   font-weight: bolder;
   margin-left: 10px;
}
.totalGame img {
   width: 60px;
}
.provider-card-flex {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   flex-wrap: wrap;
}

/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width : 1281px){

}


/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px){

}


/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px){

}


/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation : landscape){
   .container-main {
      padding: 0 15px 70px 15px;
   }
}


/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
    .container-main {
        padding: 0 15px 70px 15px;
     }
}


/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
   .container-main {
      padding: 0 15px 70px 15px;
   }
   .game-header-flex {
      display: block;
   }
   .totalGame {
       margin-bottom: 10px;
   }
}