.auth-card-body {
    padding: 20px;
}
.welcome-text {
    text-align: center;
    margin-bottom: 20px;
}
.submit-position {
    text-align: center;
}
.forgot-text {
    margin-top: 20px;
}
.forgot-text span {
    color: var(--info-color);
    transition: 0.5s;
}
.forgot-text span:hover {
    color: var(--info-hover-color);
    text-decoration: underline;
}
