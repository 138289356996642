.provider-lists-group {
  /* background-color: var(--info-color); */
  margin-top: 15px;
  border-radius: 20px;
}
.providers {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.providers li {
  margin-right: 5px;
  text-transform: uppercase;
  cursor: pointer;
}
.providers li a {
  color: #a6a4ff;
  font-weight: bolder;
  transition: 0.5s;
  padding: 10px 25px;
  border-radius: 30px;
  display: block;
}
.providers li:last-child {
  margin-right: 0;
}
.providers li.active a {
  color: #f5f5f5;
  background-color: var(--info-color);
}
.providers li:hover a {
  color: #f5f5f5;
}
.provider-cards-list {
  margin: 20px 0;
}
.title {
  font-size: 18px;
  color: hsl(0deg 0% 100% / 55%);
  margin-bottom: 15px;
}

.provider-card-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width: 1281px) {
}

/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px) {
}

/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .providers {
    width: 100%;
    overflow: auto hidden;
  }
}

/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
  .providers {
    width: 100%;
    overflow: auto hidden;
  }
}

/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
  .providers {
    width: 100%;
    overflow: auto hidden;
  }
  .providers li a {
    font-size: 12px;
    padding: 10px 15px;
  }
  .title {
    font-size: 15px;
    color: hsl(0deg 0% 100% / 55%);
    margin-bottom: 15px;
  }
}
