.footer {
    background-color: var(--main-color);
}
.payment-title {
    font-size: 18px;
    color:hsl(0deg 0% 100% / 55%);
    margin-bottom: 15px;
    /* text-align: center; */
}
.payment-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.payment-card {
    width: 25%;
    padding: 10px;
}
.payment-card-container {
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 10px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.payment-img {
    width: 70px;
    margin-right: 10px;
    border-radius: 10px;
}
.payment-text {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bolder;
    transition: 0.5s;
    width: 100%;
    text-align: center;
}
.payment-card-container:hover .payment-text {
    color: var(--info-color);
}

.contactus-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* margin-bottom: 30px; */
}
.contactus-logo {
    width: 70px;
    height: 70px;
    border: 2px solid var(--secondary-color);
    border-radius: 35px;
    cursor: pointer;
    text-align: center;
    line-height: 70px;
    font-size: 30px;
    margin: 0 10px;
    transition: 0.5s;
    margin-bottom: 20px;
}
.phone-h:hover {
    border-color: var(--success-color);
    color: var(--success-color);
}
.viber-h:hover {
    border-color: rgb(112,97,234);
    color: rgb(112,97,234);
}
.facebook-h:hover {
    border-color: rgb(60,132,231);
    color: rgb(60,132,231);
}
.copyright-text {
    text-align: center;
    color: #f5f5f5;
    margin-bottom: 0;
}
/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width : 1281px){

}


/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px){
    .footer {
        padding: 0 15px;
    }
    .payment-card {
        width: 25%;
     }
     .payment-img {
        width: 60px;
     }
     .payment-text {
        font-size: 20px;
     }
}


/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px){
    .footer {
        padding: 0 15px;
    }
    .payment-card {
        width: 33.33%;
     }
     .payment-img {
        width: 60px;
     }
     .payment-text {
        font-size: 20px;
     }
}


/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation : landscape){
    .footer {
        padding: 0 15px;
    }
    .payment-card {
        width: 33.33%;
     }
     .payment-img {
        width: 60px;
     }
     .payment-text {
        font-size: 20px;
     }
}


/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
   .footer {
    padding: 0 15px;
   }
   .payment-card {
    width: 50%;
    }
    .payment-img {
        width: 60px;
    }
    .payment-text {
        font-size: 20px;
    }
}


/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 318px) and (max-width: 480px) {
   .payment-card {
      width: 100%;
   }
   .payment-img {
      width: 30px;
   }
   .payment-text {
      font-size: 12px;
   }
   .contactus-logo {
        width: 50px;
        height: 50px;
        font-size: 20px;
        line-height: 50px;
   }
   .payment-title {
        font-size: 15px;
   }
   .copyright-text {
    font-size: 13px;
   }
}