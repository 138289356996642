.input-group {
    margin-bottom: 20px;
}

.input-container {
    position: relative;
}
.input-box {
    width: 100%;
    padding: 15px;
    border: 1px solid var(--main-color);
    outline: none;
    background-color: var(--card-color);
    box-shadow : -50px 0px 15px 2px var(--inner-shadow-color) inset;
    border-radius: 3px;
    color: #f5f5f5;
    font-size: 16px;
    border-radius: 50px;
}
.input-box:focus {
    border-color: var(--info-color);
}
.password-toggle-icon {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #d6d6d6;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -45%);
    font-size: 16px;
}
.error-message {
    margin-top: 5px;
    display: block;
    font-size: 15px;
    padding-left: 20px;
    color: red;
    white-space: pre-line;
}