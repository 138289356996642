.auth-card-body {
  padding: 20px;
}
.table-card-container {
  overflow: auto;
  font-size: 13px;
  color: #f5f5f5;
  max-height: 500px;
}
.table-card-container ul:first-child {
  position: sticky;
  top: 0;
  z-index: 99;
}
.table-card-container ul:first-child li {
  background-color: var(--info-hover-color);
}
.table-header {
  display: flex;
  margin-bottom: 10px;
}
.table-header li:nth-child(n + 2):nth-child(-n + 6) {
  flex: 0 0 120px;
}
.table-header li:first-child {
  flex: 0 0 50px;
  border-radius: 5px 0 0 5px;
}
.table-header li:last-child {
  border-radius: 0 5px 5px 0;
}
.table-header li:nth-child(7) {
  flex: 0 0 155px !important;
}

.table-header li {
  background-color: var(--main-color);
  flex: 0 0 100px;
  padding: 15px;
  text-align: center;
  white-space: nowrap;
}

/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
  .table-card-container {
    max-height: 450px;
  }
}

/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
  .table-card-container {
    max-height: 400px;
  }
}
