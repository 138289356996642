.card {
  width: 16.66%;
  padding: 10px;
}
.card-list {
  /* height: 185px;
    border-radius: 25px; */
  cursor: pointer;
  position: relative;
  user-select: none;
  transition: 0.5s;
}
.provider-text {
  margin-bottom: 0;
  font-size: 16px;
  user-select: none;
  text-align: center;
  margin-top: 5px;
}
.provider-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  transition: 0.5s;
}
.provider-img:hover {
  transform: translateY(-5px);
}
.go-game-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000070;
  transition: 0.5s;
  display: none;
  align-items: center;
  justify-content: center;
}
.card-list:hover .go-game-list {
  transition: 0.5s;
  display: flex;
}
.play-icon {
  color: var(--success-color);
  font-size: 70px;
  cursor: pointer;
}
/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width: 1281px) {
}

/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px) {
  .card {
    width: 25%;
  }
  .card-list {
    height: 180px;
  }
}

/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) {
  .card {
    width: 25%;
  }
  /* .card-list {
        height: 175px;
    } */
}

/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .card {
    width: 25%;
  }
  /* .card-list {
        height: 175px;
    } */
}

/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
  .card {
    width: 33.33%;
  }
  .provider-text {
    font-size: 14px;
  }
  /* .card-list {
        height: 165px;
    } */
}

/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
  .card {
    width: 33.33%;
  }
  .provider-text {
    font-size: 10px;
  }
  /* .card-list {
        height: 155px;
    } */
}
