.header-container {
  background-color: var(--main-color);
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
.logo {
  width: 75px;
}
.header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.btn-group {
  width: auto;
  display: flex;
}
.logo-flex {
  display: flex;
  align-items: center;
  width: 200px;
}
.menu-icon {
  font-size: 30px;
  margin-right: 20px;
  color: var(--info-color);
  cursor: pointer;
  display: none;
}
.profile {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 200px;
}
.profile .profile-img {
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;
}
.profile .user-name {
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 5px;
}
.profile .user-balance {
  font-weight: bolder;
  color: gold;
  font-size: 16px;
}
/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width: 1281px) {
}

/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px) {
}

/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) {
  .menu-icon {
    display: flex;
    align-items: center;
  }
  .btn-group  {
    display: none;
  }
}

/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .header-container {
    padding: 0;
  }
  .btn-group button:first-child,
  .btn-group button:last-child {
    font-size: 16px;
    padding: 8px 40px;
  }
  .menu-icon {
    display: flex;
    align-items: center;
  }
  .btn-group  {
    display: none;
  }
}

/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
  .btn-group button:first-child,
  .btn-group button:last-child {
    font-size: 16px;
    padding: 8px 40px;
  }
  .menu-icon {
    display: block;
  }
  .profile .user-name {
    font-size: 16px;
  }
  .profile .user-balance {
    font-size: 14px;
  }
  .btn-group  {
    display: none;
  }
}

/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
  .logo-flex {
    width: auto;
  }
  .btn-group {
    width: 200px;
    display: flex;
  }
  .btn-group button:first-child,
  .btn-group button:last-child {
    width: 100%;
    padding: 8px 10px;
  }
  .profile .profile-img {
    width: 50px;
  }
  .logo {
    width: 50px;
  }
  .menu-icon {
    display: block;
  }
  .profile .user-name {
    font-size: 14px;
  }
  .profile .user-balance {
    font-size: 14px;
  }
  .btn-group  {
    display: none;
  }
}
