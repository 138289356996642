.container-main {
    padding: 0 100px 70px 100px;
    position: relative;
}

/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width : 1281px){

}


/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px){

}


/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px){
   .container-main {
      padding: 0 15px 70px 15px;
   }
}


/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation : landscape){
   .container-main {
      padding: 0 15px 70px 15px;
   }
}


/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
    .container-main {
        padding: 0 15px 70px 15px;
     }
}


/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
   .container-main {
      padding: 0 15px 70px 15px;
   }
}