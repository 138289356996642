.banner-frame{
    height:40px;
    text-align: center;
    background: var(--main-color);
    padding:10px;
    color:#fff;
    overflow: hidden;
    font-weight: 300;
    font-size: 13px;
}
/*----------------------------------------------------------------------------- */
@media (min-width: 318px) and (max-width: 480px) {
   .banner-frame {
    font-size: 10px;
   }
}