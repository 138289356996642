.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  background-color: rgba(20, 14, 27, 0.691);
  display: flex;
  align-items: center;
  justify-content: center;
}
.ads-box {
  width: 600px;
  position: relative;
}
.ads-box .closeIcon {
  font-size: 35px;
  position: absolute;
  top: -40px;
  right: -5px;
  color: #fff;
  cursor: pointer;
}
.ads-box .adsImg {
  width: 100%;
}
