.btn {
  font-size: 16px;
  font-weight: bolder;
  padding: 10px 40px;
  border-radius: 30px;
  margin-right: 7px;
  transition: 0.5s;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  cursor: pointer;
}
.loading-container {
  display: flex;
  align-items: center;
}
.dot-flashing {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin-left: 20px;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #fff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

/*----------------------------------------------------------------------------- */
@media (min-width: 318px) and (max-width: 480px) {
  .btn {
    font-size: 12px;
  }
}
