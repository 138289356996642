.promotion_container {
  margin: 30px 0;
  gap: 20px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.promotion_item {
  border: 1px solid var(--success-color);
  background-color: var(--secondary-color);
  border-radius: 5px;
  width: 32%;
  overflow: hidden;
  position: relative;
}
.promotion_popup {
  border: 1px solid var(--success-color);
  background-color: var(--secondary-color);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.promotion_title {
  text-align: center;
  color: #ffc107;
  padding: 25px 0;
  font-weight: bolder;
}
.promotion_en_title {
  text-align: start;
  color: #ffc107;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 1.6em;
}
.promotion_mm_title {
  text-align: start;
  color: #ffc107;
  padding: 5px 20px;
  font-weight: normal;
  font-size: 1.3em;
}
.promotion_des {
  padding: 10px 20px;
  height: 200px;
  overflow: auto;
}
.promotion_period {
  display: flex;
  justify-content: space-between;
  padding: 13px 10px;
  background-color: var(--info-color);
  width: 100%;
}
.promotion_period_item {
  white-space: nowrap;
}
.promotion_action {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}
.promotion_btn {
  text-align: center;
  padding: 12px 20px;
  border: none;
  border-radius: 50px;
  width: 50%;
  cursor: pointer;
  color: #fff;
  border: 1px solid var(--secondary-color);
  transition: 0.5s;
  white-space: nowrap;
}
.promotion_btn:first-child {
  background-color: var(--info-color);
}
.promotion_btn:first-child:hover {
  transition: 0.5s;
  background-color: var(--info-hover-color);
  border: 1px solid var(--info-color);
}
.promotion_btn:last-child {
  background-color: var(--success-color);
}
.promotion_btn:last-child:hover {
  transition: 0.5s;
  background-color: var(--success-hover-color);
  border: 1px solid var(--success-color);
}

/* for promotion item responsive  */
@media (max-width: 1200px) {
  .promotion_item {
    width: 47.5%;
  }
}
@media (max-width: 680px) {
  .promotion_item {
    width: 100%;
  }
}

/* for loading container  */

.loading_container {
  display: flex;
  align-items: center;
}
.dot_flashing {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin-left: 20px;
}
.dot_flashing::before,
.dot_flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot_flashing::before {
  left: -15px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot_flashing::after {
  left: 15px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #fff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
